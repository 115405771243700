@media screen and (max-width: 992px) {
    .push-right {
        .sidebar {
            left: 235px !important;
        }
    }
}




