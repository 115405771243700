// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap; // For form validation feedback
    align-items: stretch;
    width: 100%;

    .form-control,
    .custom-select,
    .custom-file {
        position: relative; // For focus state's z-index
        flex: 1 1 auto;
        // Add width 1% and flex-basis auto to ensure that button will not wrap out
        // the column. Applies to IE Edge+ and Firefox. Chrome does not require this.
        width: 1%;
        margin-bottom: 0;

        // Bring the "active" form control to the top of surrounding elements
        &:focus {
            z-index: 3;
        }

        + .form-control {
            margin-left: -$input-border-width;
        }
    }

    .form-control,
    .custom-select {
        &:not(:last-child) {
            @include border-right-radius(0);
        }
        &:not(:first-child) {
            @include border-left-radius(0);
        }
    }

    // Custom file inputs have more complex markup, thus requiring different
    // border-radius overrides.
    .custom-file {
        display: flex;
        align-items: center;

        &:not(:last-child) .custom-file-control,
        &:not(:last-child) .custom-file-control::before {
            @include border-right-radius(0);
        }
        &:not(:first-child) .custom-file-control,
        &:not(:first-child) .custom-file-control::before {
            @include border-left-radius(0);
        }
    }
}

// Prepend and append
//
// While it requires one extra layer of HTML for each, dedicated prepend and
// append elements allow us to 1) be less clever, 2) simplify our selectors, and
// 3) support HTML5 form validation.

.input-group-prepend,
.input-group-append {
    display: flex;
    align-items: center;

    // Ensure buttons are always above inputs for more visually pleasing borders.
    // This isn't needed for `.input-group-text` since it shares the same border-color
    // as our inputs.
    .btn {
        position: relative;
        z-index: 2;
    }

    .btn + .btn,
    .btn + .input-group-text,
    .input-group-text + .input-group-text,
    .input-group-text + .btn {
        margin-left: -$input-border-width;
    }
}

.input-group-prepend {
    margin-right: -$input-border-width;
}
.input-group-append {
    margin-left: -$input-border-width;
}

// Textual addons
//
// Serves as a catch-all element for any text or radio/checkbox input you wish
// to prepend or append to an input.

.input-group-text {
    padding: $input-padding-y $input-padding-x;
    margin-bottom: 0; // Allow use of <label> elements by overriding our default margin-bottom
    margin-right:0px;
    font-size: 14px;
    font-weight: $font-weight-normal;
    line-height: $input-line-height;
    color:#585065;
    font-family: sans-serif;
    text-align: center;
    white-space: nowrap;
    background-color: #F5F5F5;
    border: $input-border-width solid $input-group-addon-border-color;
    @include border-radius($input-border-radius);

    // Nuke default margins from checkboxes and radios to vertically center within.
    input[type='radio'],
    input[type='checkbox'] {
        margin-top: 0;
    }
}
.divtextin{
    width: 48%;
    margin-right: 10px;
}
.divtextinc{
    width: 70%;
    margin-right: 15px;
    
}
.divtextprod{
    width: 45%;
    margin-right: 15px;
    justify-content: center;
  
}

.divnexprod{
    margin-left: 10px;
    margin-right: 10px;
}

.divtextinb{
    width: 20%;
}


.lefinputc{
    width: 28% !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    text-align: left !important;
    border-right: none !important;
    text-align: center !important;
}
.lefinputcn{
    width: 20% !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    text-align: left !important;
    border-right: none !important;
    text-align: center !important;
}

.lefinput{
    width: 40% !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    text-align: right !important;
}



.lefinputfech{
    width: 50% !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    font-weight: bold;
}


.puntero{
    cursor: pointer;
}

.btnbuscar{
    background-color: #1DCD9F;
    color: white;
    border-top-left-radius: 2px;
}

// Sizing
//
// Remix the default form control sizing classes into new ones for easier
// manipulation.


.inputform{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    margin-left: 0px !important;
}


.inputformb{
border-top-right-radius: 0px !important;
border-bottom-right-radius: 0px !important;
}

.textbutton{
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    margin-left: 0px !important;
}

.container-header{
    width: 100% !important;
    display: inline-flex !important;
    justify-content: center;
    
}




.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
    @extend .form-control-lg;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
    @extend .form-control-sm;
}

// Prepend and append rounded corners
//
// These rulesets must come after the sizing ones to properly override sm and lg
// border-radius values when extending. They're more specific than we'd like
// with the `.input-group >` part, but without it, we cannot override the sizing.

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    @include border-right-radius(0);
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    @include border-left-radius(0);
}

.divcontprod{
    width: 100% !important;
    display: inline-flex !important;
    
}



@media (max-width:576px){

    .divcontinp,
    .divcontprod{
        display: inline-block !important;
    }
    .divtextin,
    .divtextinc,
    .divtextinb,
    .divtextprod{
        width: 100% !important;
        align-items: center;
    }
    .divnexvta{
        margin-left: auto;
        margin-right: auto;
    }
    .grid-totals{
        display: inline-block !important;
    }
   .subtotales,
   .divtotal{
       width: 100% !important;
   }
  

  
    .containerproduct{
        width: 100% !important;
        padding: 0px !important;
        justify-content: center !important;
        margin-bottom: 28px;
    }

    .cajaproduct{
        width: 45% !important;
       }
   .divordenar{
       width: 100% !important;
   }
   .floating-button-left{
       width: 100% !important;
       text-align: center !important;
   }

   .divdescproduct{
    height: 100px !important;
}

.imageproducto{
    width: 20px !important;
    height: 20px !important;
    font-size: 15px !important;
    margin: 1px;
    align-self: center;
}
.divdesc{
   width: 90px !important;
}

.continputopc{
    margin-bottom: 30px;
}


.floating-button{
    width: 100%;
    text-align: center !important;
}
.table-aomunt{
    width: 100% !important;
}

.divnexprod{
    margin: 7px;
}

.contimage{
    display: none !important;
}
.contcategory{
    width: 100% !important;
}
}

.btn-buscar-new{
    border:1px solid transparent;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    background-color:  #939393;
    box-shadow: 0 0 0.5rem  #7c7c7c;
    cursor:pointer; 
}

.color-icon-white{
color:#fff;
}

.button-add{
    background-color: #1DCD9F;
    color: #fff;     
    height: 2.5rem;
    border-radius: 0.5rem;
    border:1px solid transparent;
    box-shadow: 2px 2px 0.5rem  #7c7c7c;
}
.padre-grid-sale{
    width: 100%;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fit,minmax(min(100%,18rem),1fr));
    grid-template-areas:
    "hijo-grid-sale-p hijo-grid-sale-p hijo-grid-sale-d";
}

.hijo-grid-sale-p{
    background:#fff;
    grid-area: hijo-grid-sale-p;
    border:1px solid #CED4DA;
    border-radius: 0.5rem;
    padding: 0.5rem;
}
.hijo-grid-sale-d{
    background: #fff;
    grid-area: hijo-grid-sale-d ;
    border:1px solid #CED4DA;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

@media (max-width:1000px){
    .padre-grid-sale{
    width: 100%;
    //padding: 1rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit,minmax(min(100%,18rem),1fr));
    grid-template-areas:
    "hijo-grid-sale-p hijo-grid-sale-d";
    }
}

@media (max-width:650px){
    .padre-grid-sale{
    width: 100%;
    //padding: 1rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit,minmax(min(100%,18rem),1fr));
    grid-template-areas:
    "hijo-grid-sale-p" 
    "hijo-grid-sale-d";
    }
}
