// stylelint-disable selector-no-qualifying-type

// Make the div behave like a button
.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle; // match .btn alignment given font-size hack above

    > .btn {
        position: relative;
        flex: 0 1 auto;

        // Bring the hover, focused, and "active" buttons to the front to overlay
        // the borders properly
        @include hover {
            z-index: 1;
        }
        &:focus,
        &:active,
        &.active {
            z-index: 1;
        }
    }

    // Prevent double borders when buttons are next to each other
    .btn + .btn,
    .btn + .btn-group,
    .btn-group + .btn,
    .btn-group + .btn-group {
        margin-left: -$btn-border-width;
    }
}

// Optional: Group multiple button groups together for a toolbar
.btn-toolbar {
  padding: 0.5rem;
  display: grid;
  width: 100%;
  grid-column-gap: 0.5rem;
  grid-row-gap: 1rem;
  grid-template-columns: repeat(auto-fit,minmax(min(100%,7.4rem),1fr));
  grid-auto-rows: 3rem;
}

.cajaalertas{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    margin-top: 5px;    
}

.grid-i-e{
    width: 100%;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit,minmax(min(100%,15rem),1fr));
    grid-auto-rows: 2rem;
    justify-content: center;
    align-items:center; 
}

.hijo-gridi-e{
    width: 100%;
    display: inline-flex;
    gap: 1rem;
}

.padre-transactions{
    width: 100%;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit,minmax(min(100%,8rem),1fr));
    grid-auto-rows: 2rem;
    justify-content: center;
    align-items:center;
}

.hijo-transactions{
    display: inline-flex;
    width: 100%;
    height: 100%;
    border-bottom:0.2rem solid #D3D3D3;
    background: #fff;
    justify-content: center;
    align-items:center;
}

.texto-transactions{
    color:#585065;
    font-weight: 400;
    font-size:0.8rem;
}


.monto-transactions{
    color:#565252;
    font-weight: bold;
    font-size:0.8rem;
}


.texto-order-rest{
    color:#585065;
    font-weight: 400;
    font-size:0.9rem;
}


.monto-order-rest{
    color:#565252;
    font-weight: bold;
    font-size:0.9rem;
}

.divcontresp{
    width: 100%;
    display: inline-flex;
    justify-content: center;
    margin: 3px;
          
}

.registros{
    font-family: sans-serif;
    font-weight:normal;
    font-size: 12px;
    color: #2c2777;  
    margin-right: 5px; 
    align-self: center;
    text-align: center;
}
.subrayado{
    border-bottom: 2px solid #939393 ;
}

.alerta{
    align-self: center;
    text-align: center;
    font-family: sans-serif;
    font-size: 12px;
    color:#FA0050;
    border-radius: 2px;
    background: #F2F2F2;
    padding-left: 2px;
    padding-right: 2px;
    
}


.cardpago > button{
    display: flex;
    width: 100%;
    height: 100%;
    border:0.1px solid #D3D3D3;
    border-radius: 0.5rem;
    box-shadow: 2px 2px 0.5rem  #7c7c7c;
    align-items: center;
    justify-content: center;
    cursor:pointer;
   
 }

 .texto-pagos{
     color:#585065;
     font-weight: bold;
 }

 
.contpay{
    
    font-size: 45px;
    border: none !important;
    
}


.btn-group {
    > .btn:first-child {
        margin-left: 0;
    }

    // Reset rounded corners
    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn {
        @include border-right-radius(0);
    }

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) > .btn {
        @include border-left-radius(0);
    }
}

// Sizing
//
// Remix the default button sizing classes into new ones for easier manipulation.

.btn-group-sm > .btn {
    @extend .btn-sm;
}
.btn-group-lg > .btn {
    @extend .btn-lg;
}

//
// Split button dropdowns
//

.dropdown-toggle-split {
    padding-right: $btn-padding-x * 0.75;
    padding-left: $btn-padding-x * 0.75;

    &::after {
        margin-left: 0;
    }
}

.btn-sm + .dropdown-toggle-split {
    padding-right: $btn-padding-x-sm * 0.75;
    padding-left: $btn-padding-x-sm * 0.75;
}

.btn-lg + .dropdown-toggle-split {
    padding-right: $btn-padding-x-lg * 0.75;
    padding-left: $btn-padding-x-lg * 0.75;
}

// The clickable button for toggling the menu
// Set the same inset shadow as the :active state
.btn-group.show .dropdown-toggle {
    @include box-shadow($btn-active-box-shadow);

    // Show no shadow for `.btn-link` since it has no other button styles.
    &.btn-link {
        @include box-shadow(none);
    }
}

//
// Vertical button groups
//

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .btn,
    .btn-group {
        width: 100%;
    }

    > .btn + .btn,
    > .btn + .btn-group,
    > .btn-group + .btn,
    > .btn-group + .btn-group {
        margin-top: -$btn-border-width;
        margin-left: 0;
    }

    // Reset rounded corners
    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn {
        @include border-bottom-radius(0);
    }

    > .btn:not(:first-child),
    > .btn-group:not(:first-child) > .btn {
        @include border-top-radius(0);
    }
}

// Checkbox and radio options
//
// In order to support the browser's form validation feedback, powered by the
// `required` attribute, we have to "hide" the inputs via `clip`. We cannot use
// `display: none;` or `visibility: hidden;` as that also hides the popover.
// Simply visually hiding the inputs via `opacity` would leave them clickable in
// certain cases which is prevented by using `clip` and `pointer-events`.
// This way, we ensure a DOM element is visible to position the popover from.
//
// See https://github.com/twbs/bootstrap/pull/12794 and
// https://github.com/twbs/bootstrap/pull/14559 for more information.

.btn-group-toggle {
    > .btn,
    > .btn-group > .btn {
        margin-bottom: 0; // Override default `<label>` value

        input[type='radio'],
        input[type='checkbox'] {
            position: absolute;
            clip: rect(0, 0, 0, 0);
            pointer-events: none;
        }
    }
}

.textdiv{
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: none !important;
    margin-right: 0px !important;
    
}




@media (max-width:576px){
    .container-header{
        width: 100%;
        display: inline-block !important;
        flex-wrap: wrap;
    }

    .texdivcont{
        width: 25% !important;
        background-color: #F2F2F2;
        
       
        
    }
    
    .divinput{
        width:75% !important;
        margin-right: 10px;
        border: none !important;
       
    }
}
