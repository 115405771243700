//
// Basic Bootstrap table
//

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: $spacer;
    background-color: $table-bg; // Reset for nesting within parents with `background-color`.
    color:#585065;
    font-family: roboto;

    th,
    td {
        padding: $table-cell-padding;
        vertical-align: top;
        border-top: $table-border-width solid $table-border-color;
    }

    thead th {
        vertical-align: bottom;
        border-bottom: (2 * $table-border-width) solid $table-border-color;
    }

    tbody + tbody {
        border-top: (2 * $table-border-width) solid $table-border-color;
    }

    .table {
        background-color: $body-bg;
    }
}

//
// Condensed table w/ half padding
//

.table-sm {
    th,
    td {
        padding: $table-cell-padding-sm;
    }
}



.headertable{
    background:$bg-nivel1;
    font-weight: 400;
    font-family: sans-serif;
    color:#666666;
    font-size:13px;
    line-height: 20px;
    
}

.totales{
    font-weight: bold;
    font-family: sans-serif;
    color:#666666;
}

.tablacompleto{
    display: grid;
    grid-row-gap:1rem;
    grid-auto-rows: auto;
    background-color:white;
    border:1px solid #D3D3D3;
    border-radius: 2px;
}

.header-expense-income{
    display: inline-flex;
    text-align: center;
    padding-top: 5px;
}


.opcionestable{
    color:#666666;
    font-family: sans-serif;
    font-weight: bold;
}
.add-detalle{
    margin-bottom: 3px;
}

#btndetallesale,
#btndetalleorder,
#btndetalleincome{
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    color:#fff;
    background-color:  #939393;
    //background:linear-gradient(150deg,#FA0050,#2c2777);
    box-shadow: 0 0 0.5rem  #7c7c7c;
    cursor:pointer; 
}

#btndetalleaddrest{
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    color:#fff;
    background-color:  #939393;
    box-shadow: 0 0 0.2rem  #7c7c7c;
    cursor:pointer; 
}

// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
    border: $table-border-width solid $table-border-color;

    th,
    td {
        border: $table-border-width solid $table-border-color;
    }

    thead {
        th,
        td {
            border-bottom-width: (2 * $table-border-width);
        }
    }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
    tbody tr:nth-of-type(odd) {
        background-color:#fff;
    }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
    tbody tr {
        @include hover {
            background-color: $table-hover-bg;
        }
    }
}

// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

@each $color, $value in $theme-colors {
    @include table-row-variant($color, theme-color-level($color, -9));
}

@include table-row-variant(active, $table-active-bg);

// Dark styles
//
// Same table markup, but inverted color scheme: dark background and light text.

// stylelint-disable-next-line no-duplicate-selectors
.table {
    .thead-dark {
        th {
            color: $table-dark-color;
            background-color: $table-dark-bg;
            border-color: $table-dark-border-color;
        }
    }

    .thead-light {
        th {
            color: $table-head-color;
            background-color: $table-head-bg;
            border-color: $table-border-color;
        }
    }
}

.table-dark {
    color: $table-dark-color;
    background-color: $table-dark-bg;

    th,
    td,
    thead th {
        border-color: $table-dark-border-color;
    }

    &.table-bordered {
        border: 0;
    }

    &.table-striped {
        tbody tr:nth-of-type(odd) {
            background-color: $table-dark-accent-bg;
        }
    }

    &.table-hover {
        tbody tr {
            @include hover {
                background-color: $table-dark-hover-bg;
            }
        }
    }
}

// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.

.table-responsive {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($next, $grid-breakpoints);

        &#{$infix} {
            @include media-breakpoint-down($breakpoint) {
                display: block;
                width: 100%;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
                -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057

                // Prevent double border on horizontal scroll due to use of `display: block;`
                > .table-bordered {
                    border: 0;
                }
            }
        }
    }
}
