//
// Visibility utilities
//

.visible {
    @include invisible(visible);
}

.invisible {
    @include invisible(hidden);
}
